import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import API from '../../api/Api';
import ErrorNotice from '../misc/ErrorNotice';

export default function Login() {
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [error, setError] = useState();

	const { setUserData } = useContext(UserContext);
	const history = useHistory();
	// const register = () => history.push('/register');

	const submit = async (e) => {
		e.preventDefault();
		try {
			const loginUser = { email, password };
			const loginRes = await API.post(`users/login`, loginUser);
			setUserData({
				token: loginRes.data.token,
				user: loginRes.data.user
			});
			localStorage.setItem('auth-token', loginRes.data.token);
			history.push('/');
		} catch (err) {
			err.response.data.msg && setError(err.response.data.msg);
		}
	};
	return (
		// <div className="page">
		// 	<h2> Log in </h2>{' '}
		// 	{error && (
		// 		<ErrorNotice message={error} clearError={() => setError(undefined)} />
		// 	)}{' '}
		// 	<form className="form" onSubmit={submit}>
		// 		<label htmlFor="login-email"> Email </label>{' '}
		// 		<input
		// 			id="login-email"
		// 			type="email"
		// 			onChange={(e) => setEmail(e.target.value)}
		// 		/>{' '}
		// 		<label htmlFor="login-password"> Password </label>{' '}
		// 		<input
		// 			id="login-password"
		// 			type="password"
		// 			onChange={(e) => setPassword(e.target.value)}
		// 		/>{' '}
		// 		<input type="submit" value="Log in" />
		// 	</form>{' '}
		// </div>
		<section className="section is-large allsection ">
			<div className="container has-text-centered">
				<div className="columns is-centered">
					<div className="column is-5 is-4-desktop">
						<form onSubmit={submit}>
							<p className="title has-text-white">Connexion RMDL</p>
							{error && (
								<ErrorNotice
									message={error}
									clearError={() => setError(undefined)}
								/>
							)}
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="email"
										placeholder="Email"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="password"
										placeholder="Password"
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
							</div>
							<div className="field is-grouped">
								<div className="control is-expanded">
									<input
										className="button is-darkred is-outlined is-fullwidth"
										type="submit"
										value="Connexion!"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}
