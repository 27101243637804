import { checkToken, getUser, isUserStaff } from "./Request";

export async function checkLoggedIn() {
    const tokenRes = await checkToken()
    if (!tokenRes.data) return false;
    const userRes = await getUser()
        
    return { user: userRes.data };
}

export async function getConnectedUser() {
    const tokenRes = await checkToken()
    if (!tokenRes.data) return { user: {}, isStaff: false };
    const userRes = await getUser()       
    const authRes = await isUserStaff(userRes.data.id)    
    return { user: userRes.data, isStaff: authRes.data };
}
export async function isUserConnected() {
    const tokenRes = await checkToken()
    if (!tokenRes.data) return { user: {} };
    const userRes = await getUser()        
    return { user: userRes.data};
}