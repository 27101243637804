import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
//import UserContext from '../../context/UserContext';
import ErrorNotice from '../misc/ErrorNotice';
import { createPost } from '../../api/Request';

export default function NewPost() {
	const date = Date.now();
	const postDate = new Date(date);
	const [title, setTitle] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [tags, setTags] = useState();
	const [image, setImage] = useState();
	const [htmlTitle1, setHTMLTitle1] = useState();
	const [htmlText1, setHTMLText1] = useState();
	const [htmlTitle2, setHTMLTitle2] = useState();
	const [htmlText2, setHTMLText2] = useState();
	const [html, setHTML] = useState();
	const [error, setError] = useState();

	//const { setUserData } = useContext(UserContext);
	const history = useHistory();
	//const seePosts = () => history.push('/posts');

	const submit = async (e) => {
		e.preventDefault();
		try {
			// await setCreatedAt(postDate)
			const newPost = {
				title,
				createdAt,
				tags,
				image,
				htmlTitle1,
				htmlText1,
				htmlTitle2,
				htmlText2,
				html
			};
			await createPost(newPost);
			history.push('/posts');
		} catch (err) {
			err.response.data.msg && setError(err.response.data.msg);
		}
	};

	const monthNames = [
		'Janvier',
		'Fevrier',
		'Mars',
		'Avril',
		'Mai',
		'Juin',
		'Juillet',
		'Août',
		'Septembre',
		'Octobre',
		'Novembre',
		'Decembre'
	];

	let formattedDate = `${postDate.getDate()} ${
		monthNames[postDate.getMonth()]
	} ${postDate.getFullYear()}`;

	return (
		<section className="section allsection">
			<div className="container has-text-centered">
				<div className="columns is-centered">
					<div className="column is-5 is-4-desktop">
						<form onSubmit={submit}>
							<h2 className="title has-text-centered mb-6">Nouvelle Annonce</h2>
							{error && (
								<ErrorNotice
									message={error}
									clearError={() => setError(undefined)}
								/>
							)}{' '}
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="text"
										placeholder="Titre"
										onChange={(e) => setTitle(e.target.value)}
									/>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="tags"
										placeholder="Tags"
										onChange={(e) => setTags(e.target.value)}
									/>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="link"
										placeholder="Image (lien)"
										onChange={(e) => setImage(e.target.value)}
									/>
									<p className="help is-link">
										Merci de remplir avec "NoImage" si vous ne souhaitez pas
										d'image
									</p>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="text"
										placeholder={formattedDate}
										// onChange={(e) => setCreatedAt(postDate)}
										readOnly
									/>
									<p className="help is-success">Date d'aujourd'hui</p>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<textarea
										className="textarea"
										rows="2"
										placeholder="Titre 1ère partie"
										onChange={(e) => setHTMLTitle1(`${e.target.value}`)}
									></textarea>
									<p className="help is-link">
										Merci de remplir avec "NoText" si vous ne souhaitez pas
										cette partie
									</p>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<textarea
										className="textarea"
										rows="4"
										placeholder="Texte 1ère partie"
										onChange={(e) => setHTMLText1(`${e.target.value}`)}
									></textarea>
									<p className="help is-link">
										Merci de remplir avec "NoText" si vous ne souhaitez pas
										cette partie
									</p>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<textarea
										className="textarea"
										rows="2"
										placeholder="Titre 2ème partie"
										onChange={(e) => setHTMLTitle2(`${e.target.value}`)}
									></textarea>
									<p className="help is-link">
										Merci de remplir avec "NoText" si vous ne souhaitez pas
										cette partie
									</p>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<textarea
										className="textarea"
										rows="4"
										placeholder="Texte 2ème partie"
										onChange={(e) => setHTMLText2(`${e.target.value}`)}
									></textarea>
									<p className="help is-link">
										Merci de remplir avec "NoText" si vous ne souhaitez pas
										cette partie
									</p>
								</div>
							</div>
							<div className="field">
								<div className="control">
									<textarea
										className="textarea"
										rows="4"
										placeholder="Texte unique"
										onChange={(e) => setHTML(`${e.target.value}`)}
									></textarea>
									<p className="help is-link">
										Merci de remplir avec "NoText" si vous ne souhaitez pas
										cette partie
									</p>
								</div>
							</div>
							<div className="field is-grouped">
								<div className="control is-expanded">
									<input
										className="button is-primary is-fullwidth"
										onClick={(e) => setCreatedAt(postDate)}
										type="submit"
										value="Poster !"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}
