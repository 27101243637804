import React from 'react';
import Axios from 'axios';
import ErrorNotice from '../../misc/ErrorNotice';
import DatePicker from '../../misc/DatePicker';
import { API_DOMAIN } from '../../../config';

class MusicListItem extends React.Component {
	state = {
		music: {},
		connectedUser: {},
		showError: false,
		showDatePicker: false,
		error: {}
	};
	constructor(props) {
		super(props);
		// this.state = {
		// 	music: {},
		// 	connectedUser: {},
		// 	error: undefined
		//   };
		this.onShowMusic = this.onShowMusic.bind(this);
		this.showError = this.showError.bind(this);
		this.renderAccept = this.renderAccept.bind(this);
		this.renderRefuse = this.renderRefuse.bind(this);
		// this.goCalendar = this.goCalendar.bind(this);
	}
	showError(type) {
		this.setState({ showError: type });
	}
	showDatePicker(type) {
		this.setState({ showDatePicker: type });
	}
	onShowMusic() {
		window.location.pathname = `admin/music/${this.props.music._id}`;
	}
	goCalendar() {
		window.location.href = `https://calendar.google.com/calendar/u/0?cid=cmFkaW9tZGwuZnZAZ21haWwuY29t`;
	}
	componentDidMount() {
		this.renderVotes();
		this.getConnectedUser();
	}
	renderAccept = async (e) => {
		try {
			let res = await Axios.post(`${API_DOMAIN}music/accept`, {
				music: this.state.music,
				user: this.state.connectedUser
			});
			console.log(res.data);
			window.location.pathname = `/admin/music`;
		} catch (err) {
			this.setState({ error: err });
			this.showError(true);
		}
	};
	renderRefuse = async (e) => {
		try {
			let res = await Axios.post(`${API_DOMAIN}music/refuse`, {
				music: this.state.music,
				user: this.state.connectedUser
			});
			console.log(res.data);
			window.location.pathname = `/admin/music`;
		} catch (err) {
			this.setState({ error: err });
			this.showError(true);
		}
	};
	renderVoteMode = async (e) => {
		try {
			let res = await Axios.post(`${API_DOMAIN}music/vote`, {
				music: this.state.music,
				user: this.state.connectedUser
			});
			console.log(res.data);
			window.location.pathname = `/admin/music`;
		} catch (err) {
			this.setState({ error: err });
			this.showError(true);
		}
	};
	async getConnectedUser() {
		let token = localStorage.getItem('auth-token');
		if (token === null) {
			return;
		}
		const tokenRes = await Axios.post(`${API_DOMAIN}users/tokenIsValid`, null, {
			headers: { 'auth-token': token }
		});
		// console.log(tokenRes.data)
		if (tokenRes.data.status) {
			const userRes = await Axios.get(`${API_DOMAIN}users/`, {
				headers: { 'auth-token': token }
			});
			// console.log(userRes);
			this.setState({ connectedUser: userRes.data });
		}
	}

	async renderVotes() {
		const res = await Axios.get(`${API_DOMAIN}music/${this.props.music._id}`);
		this.setState({ music: res.data });
	}

	renderStatusTag() {
		let status = this.state.music.status;
		if (status === 'En Attente') {
			return <span className="tag is-info is-medium">{status}</span>;
		}
		if (status === 'Accepté') {
			return <span className="tag is-primary is-medium">{status}</span>;
		}
		if (status === 'Refusé') {
			return <span className="tag is-darkred is-medium">{status}</span>;
		}
		if (status === 'En cours de vote') {
			return <span className="tag is-warning is-medium">{status}</span>;
		}
	}

	disabeling(t) {
		t.disabled = true;
		setTimeout(function () {
			t.disabled = false;
		}, 2000);
	}

	renderDate(dateString) {
		const date = new Date(dateString);

		const monthNames = [
			'Janvier',
			'Fevrier',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Decembre'
		];

		return `${date.getDate()} ${
			monthNames[date.getMonth()]
		} ${date.getFullYear()}`;
	}

	render() {
		const { music } = this.props;
		return (
			<tr>
				{this.state.showError && (
					<ErrorNotice
						message={this.state.error.msg}
						clearError={() => this.showError(false)}
					/>
				)}{' '}
				{this.state.showDatePicker && (
					<DatePicker
						music={this.state.music}
						clearError={() => this.showDatePicker(false)}
					/>
				)}{' '}
				<td onClick={() => this.onShowMusic()}>
					<a href={() => false} title={music.title}>
						{music.title}
					</a>
				</td>
				<td onClick={() => this.onShowMusic()}>{music.displayName}</td>
				<td >
					{this.state.connectedUser.displayName ? (
						<button
							className="button is-info"
							onClick={() => this.goCalendar()}
							// onClick={() => {
							// 	this.showDatePicker(true);
							// 	this.disabeling(this);
							// }}
							
						>
							Choisir une date
						</button>
					) : (
						<button
							className="button is-info"
							onClick={() => this.showDatePicker(true)}
							title="Vous devez être connecté"
							disabled
						>
							Choisir une date
						</button>
					)}
				</td>
				<td>
					{this.state.connectedUser.displayName ? (
						<button
							className="button is-link"
							onClick={() => {
								this.renderAccept();
								this.disabeling(this);
							}}
						>
							Accepter
						</button>
					) : (
						<button
							className="button is-link"
							onClick={() => this.renderAccept()}
							title="Vous devez être connecté"
							disabled
						>
							Accepter
						</button>
					)}
				</td>
				<td>
					{this.state.connectedUser.displayName ? (
						<button
							href="/"
							className="button is-darkred"
							onClick={() => {
								this.renderRefuse();
								this.disabeling(this);
							}}
						>
							Refuser
						</button>
					) : (
						<button
							className="button is-darkred"
							onClick={() => this.renderRefuse()}
							title="Vous devez être connecté"
							disabled
						>
							Refuser
						</button>
					)}
				</td>
				<td>
					{this.state.connectedUser.displayName ? (
						<button
							href="/"
							className="button is-warning"
							onClick={() => {
								this.renderVoteMode();
								this.disabeling(this);
							}}
						>
							Mode Vote
						</button>
					) : (
						<button
							className="button is-warning"
							onClick={() => this.renderVoteMode()}
							title="Vous devez être connecté"
							disabled
						>
							Mode Vote
						</button>
					)}
				</td>
				<td>{this.renderStatusTag()}</td>
			</tr>
			//   <nav className="level" onClick={this.onShowMusic}>
			//   <div className="level-item has-text-centered">
			// 	<div>
			// 	  <p className="heading">{music.title}</p>
			// 	</div>
			//   </div>
			//   <div className="level-item has-text-centered">
			// 	<div>
			// 	  <p className="heading">{music.displayName}</p>
			// 	</div>
			//   </div>
			//   <div className="level-item has-text-centered">
			// 	<div>
			// 	  <p className="heading">{music.style}</p>
			// 	</div>
			//   </div>
			//   <div className="level-item has-text-centered">
			// 	<div>
			// 	  <p className="heading">{music.status}</p>
			// 	</div>
			//   </div>
			// </nav>
			// <div className="column is-12 is-4-desktop">
			// 	<div className="card">
			// 		<div className="card-content">
			// 			<span className="is-size-7">{this.renderDate(post.createdAt)}</span>
			// 			<h5 className="title is-5">
			// 				<a href={() => false}>{post.title}</a>
			// 	</h5>
			// 	<div className=" buttons">
			// 			<a className="button is-darkred" onClick={this.onShowPost}>
			// 		Voir plus
			// 			</a>
			// 		<DeletePost />
			// 	</div>
			// 		</div>
			// 	</div>
			// </div>
		);
	}
}

export default MusicListItem;
