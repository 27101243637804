import React, { useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import UserContext from '../../../context/UserContext';
import UserList from './UserList'

export default function Users() {
	const { userData } = useContext(UserContext);
	// const history = useHistory();
	// const createPost = () => history.push('/newpost');

	return (
		<>
			{userData.user ? (
				<>
					<UserList />
				</>
			) : (
				<section className="section allsection">
					<div className="container has-text-centered ">
						<h2 className="title is-spaced">Woops !</h2>
						<p className="subtitle">
							Pour pouvoir acceder à ce panel il faut être connecté
						</p>
						<div className="buttons is-centered">
							<a className="control button is-primary" href="/login">
								Connexion
							</a>
						</div>
					</div>
				</section>
			)}
		</>
	);
}
