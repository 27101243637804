import React, { useState } from 'react';
import ErrorNotice from './ErrorNotice';
import { createProgram } from '../../api/Request';

export default function DatePicker(props) {
	const [day, setDay] = useState();
	const [startHour, setStartHour] = useState();
	const [endHour, setEndHour] = useState();
	const [title, setTitle] = useState();
	const [description, setDescription] = useState();
	const [author, setAuthor] = useState();
	const [type, setType] = useState();
	// const [hasOneDate, setHasOneDate] = useState();
	const [error, setError] = useState();

	const music = props.music;

	// const hasDate = async (musicTitle) => {

	//     let programRes;
	//     try {
	//         programRes = await Axios.get('${API_DOMAIN}program/',);
	//         setHasOneDate(programRes.data.title);
	//         // console.log(programRes.data)

	//     } catch (err) {
	//         console.log(err)
	// 		err.response.data.msg && setError(err.response.data.msg);
	//     }
	//     if (hasOneDate == musicTitle) {
	//         setHasOneDate("Attention cette musique a déjà une date de programmée")
	//     }

	// }

	const submit = async (e) => {
		e.preventDefault();
		try {
			const newProgram = {
				day,
				startHour,
				endHour,
				title,
				description,
				author,
				type
			};
			// eslint-disable-next-line no-unused-vars
			const programRes = await createProgram(newProgram);
			window.location.pathname = '/admin/music';
		} catch (err) {
			err.response.data.msg && setError(err.response.data.msg);
		}
	};
	return (
		<div className="modal is-active">
			<div className="modal-background"></div>
			<span className="modal-content">
				<div className="modal-card">
					<form onSubmit={submit}>
						<header className="modal-card-head">
							<p className="modal-card-title">Ajouter au planning</p>
							<button
								className="delete"
								aria-label="close"
								onClick={props.clearError}
							></button>
						</header>
						<section className="modal-card-body">
							{error && (
								<ErrorNotice
									message={error}
									clearError={() => setError(undefined)}
								/>
							)}
							<div className="field">
								<p className="title is-6">Date de parution </p>
								<div className="control">
									<input
										className="input"
										type="date"
										placeholder="Date de parution (dd/mm/yyyy)"
										onChange={(e) => setDay(e.target.value)}
									></input>
									<p className="help is-danger">* Renseignement Obligatoire</p>
									<p className="help is-link">
										* Merci de choisir uniquement les Mardis et Jeudis
									</p>
								</div>
							</div>
							<div className="field">
								<p className="title is-6">Heure de début</p>
								<div className="control">
									<input
										className="input"
										type="time"
										placeholder="Heure de parution (--:--)"
										onChange={(e) => setStartHour(e.target.value)}
									></input>
									<p className="help is-danger">* Renseignement Obligatoire</p>
									<p className="help is-link">
										* Merci de choisir uniquement les horraires d'émission
									</p>
								</div>
							</div>
							<div className="field">
								<p className="title is-6">Heure de fin</p>
								<div className="control">
									<input
										className="input"
										type="time"
										placeholder="Heure de parution (--:--)"
										onChange={(e) => setEndHour(e.target.value)}
									></input>
									<p className="help is-danger">* Renseignement Obligatoire</p>
									<p className="help is-link">
										* Merci de choisir uniquement les horraires d'émission
									</p>
								</div>
							</div>
						</section>
						<footer className="modal-card-foot">
							<input
								className="button is-success"
								type="submit"
								value="Save changes"
								onClick={(e) => {
									setTitle(music.title);
									setDescription(`Lecture du titre: ${music.title}`);
									setAuthor(music.displayName);
									setType('Musique');
								}}
							/>
							<button className="button" onClick={props.clearError}>
								Cancel
							</button>
						</footer>
					</form>
				</div>
			</span>
		</div>
	);
}
