import React from 'react';
 import { useHistory } from 'react-router-dom';
import AuthOptions from '../auth/AuthOptions';
import Panel from '../auth/Panel';
import Logo from '../../assets/rmdlogo.png';

export default function Header() {
	const history = useHistory();
	const home = () => history.push('/');
	const posts = () => history.push('/posts');
	const calendar = () => history.push('/calendar');
	const apropos = () => history.push('/apropos');
	const newmusic = () => history.push('/newmusic');
	const music = () => history.push('/music');

	return (
		<nav className="navbar is-black is-fixed-top">
			<div className="navbar-brand">
    			<a className="navbar-item" href={() => false} onClick={home}>
				<img src={Logo} width="100" height="100" alt=""/>
      			
    			</a>

    			<a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" href={() => false}>
      				<span aria-hidden="true"></span>
      				<span aria-hidden="true"></span>
      				<span aria-hidden="true"></span>
    			</a>
  			</div>

			<div id="navbarBasicExample" className="navbar-menu">
				
			<div className="navbar-end">
					<div className="navbar-item">
					<a href={() => false} onClick={music}>
							Musiques
					</a>
					</div>
					<div className="navbar-item">
					<a href={() => false} onClick={apropos}>
							A propos
					</a>
					</div>
					<div className="navbar-item">
					<a href={() => false} onClick={posts}>
							Annonces
					</a>
					</div>
					<div className="navbar-item">
					<a href={() => false} onClick={calendar}>
							Programme
					</a>
					</div>
					<div className="navbar-item">
					<a href={() => false} onClick={newmusic}>
							Proposer une musique
					</a>
					</div>
					<hr className="navbar-divider"/>
					<div className="navbar-item">
						<Panel />
					</div>
					<div className="navbar-item">
						<AuthOptions />
					</div>
			</div>
			</div>
			
		</nav>
	);
}
