import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import ErrorNotice from '../misc/ErrorNotice';
import MusicNote from '../../assets/musicnote.png'
import { createMusic } from '../../api/Request';

export default function SendMusic() {
	const { userData } = useContext(UserContext);
	const date = Date.now();
	const musicDate = new Date(date);
	const [displayName, setDisplayName] = useState();
	const [title, setTitle] = useState();
	const [author, setAuthor] = useState();
	const [dueDate, setDueDate] = useState();
	const [link, setLink] = useState();
	const [notes, setNotes] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [style, setStyle] = useState();
	const [status, setStatus] = useState();
	const [votes, setVotes] = useState();
	const [error, setError] = useState();
	const history = useHistory();
	// const createPost = () => history.push('/newpost');

	// const checkLoggedIn = async () => {
	// 	let token = localStorage.getItem('auth-token');
	// 	if (token === null) {
	// 		localStorage.setItem('auth-token', '');
	// 		token = '';
	// 	}
	// 	const tokenRes = await Axios.post(
	// 		'${API_DOMAIN}users/tokenIsValid',
	// 		null,
	// 		{ headers: { 'auth-token': token } }
	// 	);
	// 	if (tokenRes.data.status) {
	// 		const userRes = await Axios.get('${API_DOMAIN}users/', {
	// 			headers: { 'auth-token': token }
	// 		});
	// 	}
	// };

	const submit = async (e) => {
		e.preventDefault();
		try {
			// await setCreatedAt(postDate)
			const newMusic = {
				displayName,
				title,
				author,
				dueDate,
				link,
				notes,
				createdAt,
				style,
				status,
				votes
			};
			await createMusic(newMusic);
			history.push('/music');
		} catch (err) {
			err.response.data.msg && setError(err.response.data.msg);
		}
	};
	return (
		<>
			{userData.user ? (
				<section className="section allsection">
					<div className="container py-4">
						<h2 className="title has-text-centered has-text-white mb-6">
							Proposer sa musique
						</h2>
						<div className="columns">
							<div className="column is-6">
								<img src={MusicNote} alt="" />
							</div>
							<div className="column is-6">
								<form onSubmit={submit}>
									{error && (
										<ErrorNotice
											message={error}
											clearError={() => setError(undefined)}
										/>
									)}{' '}
									<div className="field">
										<div className="control">
											<input
												className="input"
												type="text"
												placeholder={userData.user.displayName}
												readOnly
											/>
										</div>
									</div>
									<div className="field">
										<div className="control">
											<textarea
												className="textarea"
												rows="1"
												placeholder="Titre de la musique"
												onChange={(e) => setTitle(e.target.value)}
											></textarea>
											<p className="help is-danger">
												* Renseignement Obligatoire
											</p>
										</div>
									</div>
									<div className="field">
										<div className="control">
											<textarea
												className="textarea"
												rows="1"
												placeholder="Auteur de la musique"
												onChange={(e) => setAuthor(e.target.value)}
											></textarea>
											<p className="help is-danger">
												* Renseignement Obligatoire
											</p>
										</div>
									</div>
									<div className="field">
										<div className="control">
											<input
												className="textarea"
												rows="1"
												type="date"
												placeholder="Date de parution (dd/mm/yyyy)"
												onChange={(e) => setDueDate(e.target.value)}
											></input>
											<p className="help is-danger">
												* Renseignement Obligatoire
											</p>
											<p className="help is-link">
												* Ceci est la date à laquelle vous voulez que votre
												musique soit jouée
											</p>
										</div>
									</div>
									<div className="field">
										<div className="select is-fullwidth">
											<select onChange={(e) => setStyle(e.target.value)}>
												<option selected="">Type de musique</option>
												<option>Pop</option>
												<option>Rock</option>
												<option>Jazz</option>
												<option>Soul</option>
												<option>Rap</option>
												<option>Folk</option>
												<option>Punk</option>
												<option>Metal</option>
												<option>Hip-Hop</option>
												<option>RNB</option>
												<option>Blues</option>
												<option>Country</option>
												<option>Funk</option>
												<option>Reggae</option>
												<option>Electro</option>
												<option>Classic</option>
											</select>
										</div>
										<p className="help is-danger">
											* Renseignement Obligatoire
										</p>
									</div>
									<div className="field">
										<div className="control">
											<textarea
												className="textarea"
												rows="2"
												placeholder="Lien de la musique (optionel)"
												onChange={(e) => setLink(e.target.value)}
											></textarea>
											<p className="help is-success">
												* Le lien est optionel, mais pour plus de précision il
												est conseillé
											</p>
										</div>
									</div>
									<div className="field">
										<div className="control">
											<textarea
												className="textarea"
												rows="2"
												placeholder="Notes (optionel)"
												onChange={(e) => setNotes(e.target.value)}
											></textarea>
										</div>
									</div>
									<div className="field">
										<div className="control">
											<input
												className="button is-primary is-fullwidth"
												type="submit"
												value="Envoyer"
												onClick={(e) => {
													setDisplayName(userData.user.displayName);
													setCreatedAt(musicDate);
													setVotes(0);
													setStatus('En Attente');
												}}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			) : (
				<section className="section is-large allsection">
					<div className="container has-text-centered ">
						<h2 className="title is-spaced has-text-white">Woops !</h2>
						<p className="subtitle has-text-white">
							Pour pouvoir proposer une musique il faut être connecté
						</p>
						<div className="buttons is-centered">
							<a className="control button is-primary" href="/login">
								Connexion
							</a>
							<a className="control button is-light" href="/register">
								S'inscrire
							</a>
						</div>
					</div>
				</section>
			)}
		</>
	);
}
