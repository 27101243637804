import React from 'react';
// import Moment from 'moment';

class CalendarListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onShowFile = this.onShowFile.bind(this);
	}
	onShowFile() {
		window.location.pathname = `/admin/users/${this.props.user._id}`;
	}
	fileDownload(file, date) {
		console.log(file)
		console.log(date)
		const fileUpload = require(`../../../../backend/assets/program/${date}*${file}`)
		return fileUpload
	}

	render() {
		const { file } = this.props;
		console.log(file)
		const fileDate = file.split('*')[0]
		const fileName = file.split('*')[1]
		const dateString = new Date(Number(fileDate)).toString()
		const Moment = require("moment");
		const date = Moment(dateString).format('L')
		return (
			<div className="panel-block is-block py-3 px-4">
				<a className="level" href={this.fileDownload(fileName, fileDate)} download={fileName}>
					<div className="level-left">
						<div className="level-item"><strong>Nom: </strong>{fileName}</div>
						
						<div className="level-item"><strong>Date: </strong>{date}</div>
					</div>
					<div className="level-right">
						<div className="level-item">
							<button className="button is-darkred" href={this.fileDownload(fileName, fileDate)} download={fileName}>Télécharger</button>
						</div>
					</div>
				</a>
			</div>
		);
	}
}

export default CalendarListItem;
