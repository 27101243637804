import React from 'react';
import API from '../../../api/Api';
import MusicListItem from './MusicListItem';

class MusicList extends React.Component {
	state = {
		musics: []
	};

	renderList() {
		this.state.musics.sort(function(a,b){
			// Turn your strings into dates, and then subtract them
			// to get a value that is either negative, positive, or zero.
			return new Date(b.createdAt) - new Date(a.createdAt);
		  });
		return this.state.musics.map((music, i) => {
			return <MusicListItem music={music} key={music._id} />;
		});
	}
	componentDidMount() {
		this.getMusics();
	}

	async getMusics() {
		const res = await API.get(`music/`);
		this.setState({ musics: res.data });
	}

	render() {
		return (
			<>
				<section className="section allsection">
					<div className="container has-text-centered py-4">
						<h2 className="title has-text-centered mb-6">Musiques</h2>
						<table className="table is-fullwidth">
							<thead>
								<tr>
									<th>Nom</th>
									<th>Utilisateurs</th>
									<th>Programmé pour</th>
									<th>Accepter</th>
									<th>Refuser</th>
									<th>Mode Vote</th>
									<th>Statut</th>
								</tr>
							</thead>
							<tfoot>
								<tr>
									<th>Nom</th>
									<th>Utilisateurs</th>
									<th>Programmé pour</th>
									<th>Accepter</th>
									<th>Refuser</th>
									<th>Mode Vote</th>
									<th>Statut</th>
								</tr>
							</tfoot>
							<tbody>{this.renderList()}</tbody>
						</table>
					</div>
					<nav>
						<div
							className="pagination is-centered"
							role="navigation"
							aria-label="pagination"
						>
							<a className="pagination-previous" href={() => false} disabled>
								Precedent
							</a>
							<a className="pagination-next" href={() => false} disabled>
								Suivant
							</a>
							<ul className="pagination-list">
								<li>
									<a
										className="pagination-link is-current"
										aria-label="Page 1"
										aria-current="page"
										href={() => false}
									>
										1
									</a>
								</li>
								{/* <li><a className="pagination-link" aria-label="Goto page 2">2</a></li>
        <li><a className="pagination-link" aria-label="Goto page 3">3</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 47">5</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 86">10</a></li> */}
							</ul>
						</div>
					</nav>
					{/* </div> */}
				</section>
			</>
		);
	}
}

export default MusicList;
