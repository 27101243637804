import React from "react";

export default function ErrorNotice(props) {
  return (
    <div className="notification is-darkred has-text-centered">
      <span>{props.message}</span>
      <button className="delete" onClick={props.clearError}></button>
    </div>
  );
}
