import React from 'react';
import UserListItem from './UserListItem';
import API from '../../../api/Api';
class UserList extends React.Component {
	state = {
		users: []
	};

	renderList() {
		console.log(this.state.users);
		return this.state.users.map((user, i) => {
			return <UserListItem user={user} key={user._id} />;
		});
	}

	componentDidMount() {
		this.getUsers();
	}

	async getUsers() {
		const res = await API.get(`users/all`);
		this.setState({ users: res.data });
	}

	render() {
		return (
			<>
				<section className="section allsection">
					{/* <div className="box"> */}
					<nav className="breadcrumb" aria-label="breadcrumbs">
						<ul>
							<li className="is-active">
								<a href={() => false} aria-current="page">
									Utilisateurs
								</a>
							</li>
						</ul>
					</nav>
					{/* </div> */}

					<div className="panel">
						<h2 className="title has-text-centered mb-6">Utilisateurs</h2>
						<nav className="level">
							<div className="level-left">
								<div className="level-item">
									<p className="subtitle is-5">
										<strong>{this.state.users.length}</strong> utilisateurs
									</p>
								</div>
								<div className="level-item">
									<div className="field has-addons">
										<p className="control">
											<input
												className="input"
												type="text"
												placeholder="Trouver un utilisateur"
											/>
										</p>
										<p className="control">
											<button className="button is-darkred">Chercher</button>
										</p>
									</div>
								</div>
							</div>

							<div className="level-right">
								<p className="level-item">
									<strong>Tous</strong>
								</p>
								<p className="level-item">
									<a href={() => false}>Utilisateurs</a>
								</p>
								<p className="level-item">
									<a href={() => false}>Animateurs</a>
								</p>
								<p className="level-item">
									<a href={() => false}>Admins</a>
								</p>
							</div>
						</nav>
						<div className="panel-block is-block py-3 px-4">
							{this.renderList()}
						</div>
					</div>
					<nav>
						<div
							className="pagination is-centered"
							role="navigation"
							aria-label="pagination"
						>
							<a className="pagination-previous" href={() => false} disabled>
								Precedent
							</a>
							<a className="pagination-next" href={() => false} disabled>
								Suivant
							</a>
							<ul className="pagination-list">
								<li>
									<a
										className="pagination-link is-current"
										aria-label="Page 1"
										aria-current="page"
										href={() => false}
									>
										1
									</a>
								</li>
								{/* <li><a className="pagination-link" aria-label="Goto page 2">2</a></li>
        <li><a className="pagination-link" aria-label="Goto page 3">3</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 47">5</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 86">10</a></li> */}
							</ul>
						</div>
					</nav>
					{/* </div> */}
				</section>
			</>
		);
	}
}

export default UserList;
