import React from 'react';

class PostListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onShowPost = this.onShowPost.bind(this);
	}
	onShowPost() {
		window.location.pathname = `/posts/${this.props.post._id}`;
	}
	renderDate(dateString) {
		const date = new Date(dateString);

		const monthNames = [
			'Janvier',
			'Fevrier',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Decembre'
		];

		return `${date.getDate()} ${
			monthNames[date.getMonth()]
		} ${date.getFullYear()}`;
	}

	renderTags(tags) {
		return tags.map((tag) => {
			return <span key={tag}>{tag}</span>;
		});
	}

	render() {
		const { post } = this.props;
		return (
			// <section>
			// 	<button onClick={this.onShowPost}>
			// 		<h3 className="title has-text-white">{post.title}</h3>
			// 		<span className="date">{this.renderDate(post.createdAt)}</span>
			// 		<div className="tag">{this.renderTags(post.tags)}</div>
			// 	</button>
			// </section>
			<div className="column is-12 is-4-desktop">
				<div className="card">
					<div className="card-content">
						<span className="is-size-7">{this.renderDate(post.createdAt)}</span>
						<h5 className="title is-5">
							<a href={() => false}>{post.title}</a>
						</h5>
						<div className=" buttons">
							<a
								className="button is-darkred"
								href={() => false}
								onClick={this.onShowPost}
							>
								Voir plus
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PostListItem;
