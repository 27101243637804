import React from 'react';
import userLogo from '../../assets/user.png'

export default function Apropos() {
	return (
		<>
			<section className="section is-medium allsection">
				<div className="container has-text-centered has-text-white">
					<h2 className="title has-text-white">La Radio Maison des lycéens</h2>
					<p>
						La RMDL est un club qui propose des rendez-vous musicaux et de
						sujets d'actualités dans l'Agora
					</p>
				</div>
			</section>
			<section className="section">
				<div className="container has-text-centered py-4">
					<h2 className="title has-text-white">Le Staff de la RMDL</h2>
					<p className="subtitle mb-6 has-text-white">
						Voici le staff de la RMDL qui font ce qu'elle est maintenant
					</p>
					<div className="columns is-centered is-multiline py-5">
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={userLogo}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Vincenzo Avoni</h5>
							<p className="subtitle is-6 has-text-white">
								Président de la RMDL / DJ
							</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={userLogo}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Tim Suykerbuyk</h5>
							<p className="subtitle is-6 has-text-white">
								Vice-Président / Technicien / Développeur / DJ
							</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={userLogo}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Alexandru Bajdechi</h5>
							<p className="subtitle is-6 has-text-white">
								Annonciateur Informations
							</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={userLogo}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Adrien Tartaroli</h5>
							<p className="subtitle is-6 has-text-white">Standardiste</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={userLogo}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Nous recruton</h5>
							<p className="subtitle is-6 has-text-white">Poste 1</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={userLogo}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Nous recrutons</h5>
							<p className="subtitle is-6 has-text-white">Poste 2</p>
						</div>
						<div className="column is-6 is-3-widescreen mb-6">
							<div className="level">
								<div className="level-item">
									<figure className="image is-128x128">
										<img
											className="is-rounded"
											src={userLogo}
											alt=""
										/>
									</figure>
								</div>
							</div>
							<h5 className="title is-5 has-text-white">Nous recrutons</h5>
							<p className="subtitle is-6 has-text-white">Poste 3</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
