import React, { useState } from 'react';
import API from '../../api/Api';
import ErrorNotice from '../misc/ErrorNotice';

export default function PasswordModifier(props) {
	const [_id, set_id] = useState();
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [passwordCheck, setPasswordCheck] = useState();
	// const [displayName, setDisplayName] = useState();
	const [error, setError] = useState();

	const user = props.user;

	const submit = async (e) => {
		e.preventDefault();
		try {
			const updatePassword = { _id, email, password, passwordCheck };
			const updateUser = await API.post(
				`users/updatepassword`,
				updatePassword
			);
			window.location.pathname = `/admin/users/${props.user._id}`;
		} catch (err) {
			err.response.data.msg && setError(err.response.data.msg);
		}
	};
	return (
		<div className="modal is-active">
			<div className="modal-background"></div>
			<span className="modal-content">
				<div className="modal-card">
					<form onSubmit={submit}>
						<header className="modal-card-head">
							<p className="modal-card-title">Modification Mot de Passe</p>
							<button
								className="delete"
								aria-label="close"
								onClick={props.clearError}
							></button>
						</header>
						<section className="modal-card-body">
							{error && (
								<ErrorNotice
									message={error}
									clearError={() => setError(undefined)}
								/>
							)}
							<div className="field">
								<p className="title is-6">Mot de passe</p>
								<div className="control">
									<input
										className="input"
										type="password"
										placeholder="Mot de passe"
										onChange={(e) => setPassword(e.target.value)}
									></input>
									<p className="help is-danger">* Renseignement Obligatoire</p>
								</div>
							</div>
							<div className="field">
								<p className="title is-6">Confirmer mot de passe</p>
								<div className="control">
									<input
										className="input"
										type="password"
										placeholder="Confirmer mot de passe"
										onChange={(e) => setPasswordCheck(e.target.value)}
									></input>
									<p className="help is-danger">* Renseignement Obligatoire</p>
								</div>
							</div>
						</section>
						<footer className="modal-card-foot">
							<input
								className="button is-success"
								type="submit"
								value="Changer"
								onClick={() => {
									setEmail(user.email);
									set_id(user._id);
								}}
							/>
							<button className="button" onClick={props.clearError}>
								Cancel
							</button>
						</footer>
					</form>
				</div>
			</span>
		</div>
	);
}
