import React from 'react';
import { getMusic } from '../../api/Request';

class Music extends React.Component {
	state = {
		user: {},
		music: {}
	};
	componentDidMount() {
		this.getMusic();
		this.getMusic();
	}
	async getMusic() {
		const res = await getMusic()
		this.setState({ music: res.data });
	}
	renderTitle() {
		return { title: this.state.music.title };
	}
	renderName() {
		return { displayName: this.state.music.displayName };
	}
	renderLink() {
		return { title: this.state.music.link };
	}
	renderMusicDate() {
		return { date: `${this.state.music.createdAt}` };
	}
	renderDueDate() {
		return { dueDate: `${this.state.music.dueDate}` };
	}
	renderDate(dateString) {
		const date = new Date(dateString);

		const monthNames = [
			'Janvier',
			'Fevrier',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Decembre'
		];

		return `${date.getDate()} ${
			monthNames[date.getMonth()]
		} ${date.getFullYear()}`;
	}
	renderStyle() {
		return { style: this.state.music.style };
	}
	renderStatus() {
		return { status: this.state.music.status };
	}
	renderStatusTag() {
		let status = this.state.music.status;
		if (status === 'En Attente') {
			return <span className="tag is-info is-medium">{status}</span>;
		}
		if (status === 'Accepté') {
			return <span className="tag is-primary is-medium">{status}</span>;
		}
		if (status === 'Refusé') {
			return <span className="tag is-darkred is-medium">{status}</span>;
		}
		if (status === 'En cours de vote') {
			return <span className="tag is-warning is-medium">{status}</span>;
		}
	}

	// renderPost() {
	// 	return <div dangerouslySetInnerHTML={this.renderHTML()}></div>;
	// }
	render() {
		return (
			<section className="section allsection">
				<div className="container py-4">
					<h2 className="title has-text-centered mb-6">
						Musique: {this.state.music.title}
					</h2>
					<div className="container has-text-centered py-4">
						<h2 className="title mb-6">Informations</h2>
						<div className="section allsection">
							<div className="columns">
								<div className="column is-3">
									<h4 className="title is-spaced is-4">Auteur:</h4>
									<p className="subtitle is-6">{this.state.music.author}</p>
								</div>
								<div className="column is-3">
									<h4 className="title is-spaced is-4">Style:</h4>
									<p className="subtitle is-6">{this.state.music.style}</p>
								</div>
								<div className="column is-3">
									<h4 className="title is-spaced is-4">Lien:</h4>
									<p className="subtitle is-6">{this.state.music.link}</p>
								</div>
								<div className="column is-3">
									<h4 className="title is-spaced is-4">Statut:</h4>
									<p className="subtitle is-6">{this.renderStatusTag()}</p>
								</div>
							</div>
						</div>
						<div className="section">
							<div className="columns">
								<div className="column is-3">
									<h4 className="title is-spaced is-4">Proposé par:</h4>
									<p className="subtitle is-6">
										{this.state.music.displayName}
									</p>
								</div>
								<div className="column is-3">
									<h4 className="title is-spaced is-4">Proposé le:</h4>
									<p className="subtitle is-6">
										{this.renderDate(this.state.music.createdAt)}
									</p>
								</div>
								<div className="column is-3">
									<h4 className="title is-spaced is-4">Proposé pour:</h4>
									<p className="subtitle is-6">
										{this.renderDate(this.state.music.dueDate)}
									</p>
								</div>
								<div className="column is-3">
									<h4 className="title is-spaced is-4">Nombres de votes:</h4>
									<p className="subtitle is-6">{this.state.music.votes}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
export default Music;
