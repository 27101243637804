import React, { useState, useEffect } from 'react';
import { useHistory, Switch, Route, withRouter } from 'react-router-dom';
import { checkLoggedIn } from './api/Functions'
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './components/pages/Home';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import UserContext from './context/UserContext';
import PostList from './components/posts/PostList';
import Post from './components/posts/Post';
import NewPost from './components/posts/NewPost';
import SendMusic from './components/music/SendMusic';
import Apropos from './components/pages/Apropos';
import Page404 from './components/pages/404page';
import Admin from './components/admin/Manager';
import Users from './components/admin/users/Users';
import User from './components/admin/users/User';
import ADMMusic from './components/admin/music/Music';
import ADMMusicList from './components/admin/music/MusicList';
import Music from './components/music/Music';
import MusicList from './components/music/MusicList';
import Calendar from './components/pages/Calendar';
import CalendarList from './components/pages/CalendarList';

import './main.css';

function Router() {
    const [userData, setUserData] = useState({ user: undefined });
    
	const history = useHistory();
	const go404 = () => history.push('/404');

	useEffect(() => {
		const loginChecker = async () => {
            const userRes = await checkLoggedIn()
            if (userRes) setUserData({user: userRes.user});
		};

		loginChecker();
	}, []);

	return (
		<UserContext.Provider value={{ userData, setUserData }}>
			<Header />
			<div>
				<Switch>
                    <Route exact path="/" component={Home} />{' '}
                    
					<Route path="/login" component={Login} />{' '}
					<Route path="/register" component={Register} />{' '}
					<Route path="/newmusic" component={SendMusic} />{' '}
					<Route path="/apropos" component={Apropos} />{' '}
                    <Route path="/404" component={Page404} />{' '}
					<Route exact path="/calendar/lists" component={CalendarList} />{' '}
					<Route exact path="/calendar" component={Calendar} />{' '}
                    
					<Route exact path="/admin/music/:id" component={ADMMusic} />
					<Route exact path="/admin/music" component={ADMMusicList} />{' '}
					<Route exact path="/admin/users/:id" component={User} />
					<Route exact path="/admin/users" component={Users} />{' '}
					<Route exact path="/admin" component={Admin} />{' '}
					<Route exact path="/music/:id" component={Music} />{' '}
					<Route exact path="/music" component={MusicList} />{' '}
					<Route exact path="/posts/:id" component={Post} />
					<Route exact path="/posts" component={PostList} />
					<Route exact path="/newpost" component={NewPost} />
					<Route render={go404} />{' '}
				</Switch>{' '}
			</div>
			<Footer />
		</UserContext.Provider>
	);
}

export default withRouter(Router);
