import React from 'react';

class UserListItem extends React.Component {
	constructor(props) {
		super(props);

		this.onShowUser = this.onShowUser.bind(this);
	}
	onShowUser() {
		window.location.pathname = `/admin/users/${this.props.user._id}`;
	}

	render() {
		console.log(this.props);
		const { user } = this.props;
		return (
			<div className="panel-block is-block py-3 px-4">
				<a className="level" onClick={this.onShowUser} href={() => false}>
					<div className="level-left">
						<div className="level-item"><strong>Nom: </strong>{user.displayName}</div>
						
						<div className="level-item"><strong>Email: </strong>{user.email}</div>
					</div>
					<div className="level-right">
						<div className="level-item">
							<button className="button is-darkred" onClick={this.onShowUser}>Voir plus</button>
						</div>
					</div>
				</a>
			</div>
		);
	}
}

export default UserListItem;
