import React, { useState } from 'react';
import API from '../../api/Api';
import { deleteUser } from '../../api/Request';
import ErrorNotice from '../misc/ErrorNotice';

export default function UserDeleter(props) {
	const [id, setId] = useState();
	const [error, setError] = useState();

	const user = props.user;

	const submit = async (e) => {
		e.preventDefault();
		try {
			// eslint-disable-next-line no-unused-vars
			const userDelete = await deleteUser(id);
			window.location.pathname = `/admin/users`;
		} catch (err) {
			err.response.data.msg && setError(err.response.data.msg);
		}
	};
	return (
		<div className="modal is-active">
			<div className="modal-background"></div>
			<span className="modal-content">
				<div className="modal-card">
					<form onSubmit={submit}>
						<header className="modal-card-head">
							<p className="modal-card-title">Suppression du compte</p>
							<button
								className="delete"
								aria-label="close"
								onClick={props.clearError}
							></button>
						</header>
						<section className="modal-card-body">
							{error && (
								<ErrorNotice
									message={error}
									clearError={() => setError(undefined)}
								/>
							)}
							<div className="title is-4">
								Êtes-vous sûr de vouloir supprimer le compte ? Il n'y a pas de
								retour en arrière possible
							</div>
						</section>
						<footer className="modal-card-foot">
							<input
								className="button is-darkred"
								type="submit"
								value="Oui"
								onClick={() => {
									setId(user._id);
								}}
							/>
							<button className="button" onClick={props.clearError}>
								Non
							</button>
						</footer>
					</form>
				</div>
			</span>
		</div>
	);
}
