/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import API from '../../api/Api';
import { getConnectedUser } from '../../api/Functions';
import { checkToken, uploadFile } from '../../api/Request';
import CalendarListItem from './CalendarListItem';
// import fs from 'fs';

// export default function CalendarList() {
// 	const [selectedFile, setSelectedFile] = useState();
//     const [savedFiles, setsavedFiles] = useState();
//     const [isFilePicked, setIsFilePicked] = useState(false);

//     const fileSave = async () => {
//         const data = new FormData()
//         data.append('file', selectedFile)
//         // const date = new Date(Date.now());
// 		// create a JSON object
//         // const fileName = selectedFile.name
//         // const file = {
// 	    //     '': {
//         //         date,
//         //     },
// 		// };

// 		// convert JSON object to string
// 		// const data = JSON.stringify(file);

// 		// write JSON string to a file
//         // const blob = new Blob([data], {type: "text/plain"});
//         // const url = URL.createObjectURL(blob);
//         // const link = document.createElement('a');
//         // console.log(url)
//         // console.log(link)
//         // link.download = 'filename.json';
//         // link.href = url;
//         // link.click();

//         // const fileData = { date, fileName: selectedFile.name };
//         const res = await Axios.post(`http://localhost:5000/music/fileUpload`, data);
//             console.log(res)
// 		// fs.writeFile('../../assets/program/registry.json', data, (err) => {
// 		// 	if (err) {
// 		// 		throw err;
// 		// 	}
// 		// 	console.log('JSON data is saved.');
// 		// });
// 	};
// 	const changeHandler = (event) => {
// 		setSelectedFile(event.target.files[0]);
// 		setIsFilePicked(true);
//     };
//     const allFiles = async () => {
//         const res = await Axios.get(`http://localhost:5000/music/files`);
//         setsavedFiles(res.data);
//     }
//     const fileName = async () => {
//         savedFiles.map((file) => {
//             <CalendarListItem file={file} key={null}/>
//         })
//     }
//     useEffect(() => {
//         allFiles()
//      }, []);

// 	return (
//         <section className="section is-medium allsection">
//                 <div>
//                 </div>
//             {isFilePicked ? (
// 				<div class="file has-name">
// 					<label class="file-label">
// 						<input class="file-input" type="file" name="resume" />
// 						<span class="file-cta">
// 							<span class="file-icon">
// 								<i class="fas fa-upload"></i>
// 							</span>
// 							<span class="file-label">Choose a file…</span>
// 						</span>
// 						<span class="file-name">{selectedFile.name}</span>
//                     </label>
//                     <button className="button is-darkred" onClick={fileSave}>
// 							Envoyer
// 						</button>
// 				</div>
// 			) : (
// 				<div class="file">
// 					<label class="file-label">
// 						<input
// 							class="file-input"
// 							type="file"
// 							name="resume"
// 							onChange={changeHandler}
// 						/>
// 						<span class="file-cta">
// 							<span class="file-icon">
// 								<i class="fas fa-upload"></i>
// 							</span>
// 							<span class="file-label">Choose a file…</span>
// 						</span>
// 					</label>
// 				</div>
// 			)}
// 		</section>
// 	);
// }

class CalendarList extends React.Component {
    state = {
        connectedUser: {},
        authRes: {},
		selectedFile: {},
		savedFiles: [],
		isFilePicked: false
	};

	async fileSave() {
		const data = new FormData();
		data.append('file', this.state.selectedFile);
		const res = await uploadFile(data);
        window.location.pathname = '/calendar/lists';
	}

	renderList() {
		// console.log(this.state.savedFiles);
		return this.state.savedFiles.map((file, i) => {
			return <CalendarListItem file={file} />;
		});
	}


	componentDidMount() {
        this.getConnectedUser();
        this.getFiles();
    }
    
	async getConnectedUser() {
		const res = await getConnectedUser()
		this.setState({ connectedUser: res.user });
		this.setState({ authRes: res.isStaff });

	}

	async getFiles() {
		const res = await API.get(`music/files`);
		this.setState({ savedFiles: res.data });
	}

	render() {
        return (
            <section className="section allsection">
                                {this.state.authRes && (
                    <div>
                        {this.state.isFilePicked ? (
                            <div class="file has-namen">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="resume" />
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label">Choose a file…</span>
                                    </span>
                                    <span class="file-name">{this.state.selectedFile.name}</span>
                                </label>
                                <button
                                    className="button is-darkred"
                                    onClick={this.fileSave.bind(this)}
                                >
                                    Envoyer
						</button>
                            </div>
                        ) : (
                            <div class="file">
                                <label class="file-label">
                                    <input
                                        class="file-input"
                                        type="file"
                                        name="resume"
                                        onChange={(e) =>
                                            this.setState({
                                                selectedFile: e.target.files[0],
                                                isFilePicked: true
                                            })
                                        }
                                    />
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label">Choose a file…</span>
                                    </span>
                                </label>
                            </div>
                        )}
                    </div>
                )}
				{/* <div className="box"> */}
				<nav className="breadcrumb" aria-label="breadcrumbs">
					<ul>
						<li className="is-active">
							<a href={() => false} aria-current="page">
								Liste des programmes
							</a>
						</li>
					</ul>
				</nav>
				{/* </div> */}

				<div className="container py-4">
					<div className="box">
						<h2 className="title has-text-centered mb-6">
							Les derniers fichiers
						</h2>

						<div className="panel-block is-block py-3 px-4">
							{this.renderList()}
                            {/* {this.state.savedFiles.map((file, i) => (
                        
                                <div className="panel-block is-block py-3 px-4">
                                    <a className="level" href={() => false}>
                                        <div className="level-left">
                                            <div className="level-item"><strong>Nom: </strong>{file.name }</div>
						
                                            <div className="level-item"><strong>Date: </strong>{file.name }</div>
                                        </div>
                                        <div className="level-right">
                                            <div className="level-item">
                                                <button className="button is-darkred" >Télécharger</button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))} */}
                        </div>
					</div>
					<nav>
						<div
							className="pagination is-centered"
							role="navigation"
							aria-label="pagination"
						>
							<a className="pagination-previous" href={() => false} disabled>
								Precedent
							</a>
							<a className="pagination-next" href={() => false} disabled>
								Suivant
							</a>
							<ul className="pagination-list">
								<li>
									<a
										className="pagination-link is-current"
										aria-label="Page 1"
										aria-current="page"
                                        href={() => false}
									>
										1
									</a>
								</li>
								{/* <li><a className="pagination-link" aria-label="Goto page 2">2</a></li>
        <li><a className="pagination-link" aria-label="Goto page 3">3</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 47">5</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 86">10</a></li> */}
							</ul>
						</div>
					</nav>
				</div>
                    </section>
                    
		);
	}
}

export default CalendarList;
