import React from 'react';
import ErrorNotice from '../misc/ErrorNotice';
import ThumbUp from '../../assets/thumb_up_icon_144077.png'
import ThumbDown from '../../assets/thumb_down_icon_144078.png'
import { isUserConnected } from '../../api/Functions';
import { dislikeMusic, getMusic, likeMusic } from '../../api/Request';

class MusicListItem extends React.Component {
	state = {
		music: {},
		connectedUser: {},
		showError: false,
		error: {}
	};
	constructor(props) {
		super(props);
		// this.state = {
		// 	music: {},
		// 	connectedUser: {},
		// 	error: undefined
		//   };
		this.onShowMusic = this.onShowMusic.bind(this);
		this.showError = this.showError.bind(this);
		this.renderLike = this.renderLike.bind(this);
		this.renderUnlike = this.renderUnlike.bind(this);
	}
	showError(type) {
		this.setState({ showError: type });
	}
	onShowMusic() {
		window.location.pathname = `/music/${this.props.music._id}`;
	}
	componentDidMount() {
		this.renderVotes();
		this.getConnectedUser();
	}
	renderLike = async (e) => {
		try {
			if (this.state.music.status !== 'En cours de vote') {
				this.setState({
					error: {
						msg:
							'Vous ne pouvez pas voter pour une musique qui est déjà acceptée ou refusée'
					}
				});
				this.showError(true);
				return;
			}
			let res = await likeMusic(this.state.music, this.state.connectedUser)
			if (res.data.status === 401) {
				this.setState({ error: res.data });
				this.showError(true);
			} else {
				window.location.pathname = `/music`;
			}
		} catch (err) {
			this.setState({ error: err });
			this.showError(true);
		}
	};
	renderUnlike = async (e) => {
		try {
			if (this.state.music.status !== 'En cours de vote') {
				this.setState({
					error: {
						msg:
							'Vous ne pouvez pas voter pour une musique qui est déjà acceptée ou refusée'
					}
				});
				this.showError(true);
				return;
			}
			let res = await dislikeMusic(this.state.music, this.state.connectedUser)
			if (res.data.status === 401) {
				this.setState({ error: res.data });
				this.showError(true);
			} else {
				window.location.pathname = `/music`;
			}
		} catch (err) {
			this.setState({ error: err });
			this.showError(true);
		}
	};
	async getConnectedUser() {
		const user = await isUserConnected()
		this.setState({ connectedUser: user });
	}

	async renderVotes() {
		const res = await getMusic(this.props.music._id)
		this.setState({ music: res.data });
	}

	disabeling(t) {
		t.disabled = true;
		setTimeout(function () {
			t.disabled = false;
		}, 2000);
	}

	renderDate(dateString) {
		const date = new Date(dateString);

		const monthNames = [
			'Janvier',
			'Fevrier',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Decembre'
		];

		return `${date.getDate()} ${
			monthNames[date.getMonth()]
		} ${date.getFullYear()}`;
	}

	renderStatusTag() {
		let status = this.state.music.status;
		if (status === 'En Attente') {
			return <span className="tag is-info is-medium">{status}</span>;
		}
		if (status === 'Accepté') {
			return <span className="tag is-primary is-medium">{status}</span>;
		}
		if (status === 'Refusé') {
			return <span className="tag is-darkred is-medium">{status}</span>;
		}
		if (status === 'En cours de vote') {
			return <span className="tag is-warning is-medium">{status}</span>;
		}
	}

	render() {
		const { music } = this.props;
		return (
			<tr>
				{this.state.showError && (
					<ErrorNotice
						message={this.state.error.msg}
						clearError={() => this.showError(false)}
					/>
				)}{' '}
				<td onClick={() => this.onShowMusic()}>
					<a href={() => false} title={music.title}>
						{music.title}
					</a>
				</td>
				<td onClick={() => this.onShowMusic()}>{music.displayName}</td>
				<td onClick={() => this.onShowMusic()}>{music.style}</td>
				<td>
					{this.state.connectedUser.displayName ? (
						<button
							className="button is-link"
							onClick={() => {
								this.renderLike();
								// window.location = "/music"
								this.disabeling(this);
							}}
						>
							<img
								src={ThumbUp}
								alt=""
								height="20px"
								width="20px"
							/>
						</button>
					) : (
						<button
							className="button is-link"
							onClick={() => this.renderLike()}
							title="Vous devez être connecté"
							disabled
						>
							<img
								src={ThumbUp}
								alt=""
								height="20px"
								width="20px"
							/>
						</button>
					)}
				</td>
				<td>
					{this.state.connectedUser.displayName ? (
						<button
							href="/"
							className="button is-darkred"
							onClick={() => {
								this.renderUnlike();
								// window.location = "/music"
								this.disabeling(this);
							}}
						>
							<img
								src={ThumbDown}
								alt=""
								height="20px"
								width="20px"
							/>
						</button>
					) : (
						<button
							className="button is-darkred"
							onClick={() => this.renderUnlike()}
							title="Vous devez être connecté"
							disabled
						>
							<img
								src={ThumbDown}
								alt=""
								height="20px"
								width="20px"
							/>
						</button>
					)}
				</td>
				<td>{music.votes}</td>
				<td>
					{/* <button
						className="button is-darkred"
						onClick={() => this.onShowMusic()}
					> */}
					{this.renderStatusTag()}
					{/* </button> */}
				</td>
				<td>
					<button
						className="button is-darkred"
						onClick={() => this.onShowMusic()}
					>
						Voir Plus
					</button>
				</td>
			</tr>
			//   <nav className="level" onClick={this.onShowMusic}>
			//   <div className="level-item has-text-centered">
			// 	<div>
			// 	  <p className="heading">{music.title}</p>
			// 	</div>
			//   </div>
			//   <div className="level-item has-text-centered">
			// 	<div>
			// 	  <p className="heading">{music.displayName}</p>
			// 	</div>
			//   </div>
			//   <div className="level-item has-text-centered">
			// 	<div>
			// 	  <p className="heading">{music.style}</p>
			// 	</div>
			//   </div>
			//   <div className="level-item has-text-centered">
			// 	<div>
			// 	  <p className="heading">{music.status}</p>
			// 	</div>
			//   </div>
			// </nav>
			// <div className="column is-12 is-4-desktop">
			// 	<div className="card">
			// 		<div className="card-content">
			// 			<span className="is-size-7">{this.renderDate(post.createdAt)}</span>
			// 			<h5 className="title is-5">
			// 				<a href={() => false}>{post.title}</a>
			// 	</h5>
			// 	<div className=" buttons">
			// 			<a className="button is-darkred" onClick={this.onShowPost}>
			// 		Voir plus
			// 			</a>
			// 		<DeletePost />
			// 	</div>
			// 		</div>
			// 	</div>
			// </div>
		);
	}
}

export default MusicListItem;
