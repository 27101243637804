// import { useContext } from 'react';
import UserContext from '../context/UserContext';
import API from './Api';

export function getContext() {
    // const { userData } = useContext(UserContext);
    <UserContext.Consumer>
        {({ userData, getUserData }) => {
            console.log(userData)
            return userData;
        }}
    </UserContext.Consumer>
    // return userData;
}

export function checkToken() {
    return API.get(`users/tokenIsValid`, {withCredentials: true});
}

export function getUsers() {
    return API.get('users/all', {withCredentials: true});
}

export function getUser() {
    return API.get('users/', {withCredentials: true});
}

export function getPosts() {
    return API.get(`posts/`);
}

export function getPost(id) {
    return API.get(`posts/${id}`);
}

export function createPost(newPost) {
    return API.post(`posts/newpost`, newPost);
}

export function deletePost(id) {
    return API.post(`posts/${id}`);
}

export function createMusic(newMusic) {
    return API.post(`music/newMusic`, newMusic);
}

export function getMusics() {
    return API.get(`music/`);
}

export function getMusic(id) {
    return API.get(`music/${id}`);
}

export function likeMusic(music, user) {
    return API.post(`music/like`, { music, user });
}

export function dislikeMusic(music, user) {
    return API.post(`music/dislike`, { music, user });
}

export function uploadFile(data) {
    return API.post(`music/fileUpload`, data);
}

export function isUserStaff(id) {
    return API.get(`users/isStaff/${id}`, {withCredentials: true});
}

export function createProgram(newProgram) {
    return API.post(`program/newprogram`, newProgram);
}

export function deleteUser(id) {
    return API.delete(`users/deletebyid/${id}`,);
}
