import React from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/rmdlogo.png'

export default function Footer() {
	const history = useHistory();

	const login = () => history.push('/login');
	const register = () => history.push('/register');
	const contact = () => history.push('/contact');
	const about = () => history.push('/apropos');
	return (
		<footer className="footer">
            <div className="container">
            <div className="column is-half is-offset-one-quarter is-hidden-mobile">
					<img className="myimage" src={Logo} alt=""/>
					</div>
                <div className="columns">
                    
                    <div className="column is-4 has-text-centered is-hidden-tablet">
                        <img className="myimage" src={Logo} alt=""/>
					</div>
					<div className="column is-4 ">
						<div className="level">
							<a className="level-item" href={() => false} onClick={login}>
								Connexion
							</a>
							<a className="level-item" href={() => false} onClick={register}>
								S'inscrire
							</a>
						</div>
                    </div>
                    <div className="column is-4 has-text-centered is-hidden-mobile">
                    {/* <img className="image is-centered" src={Logo} alt=""/> */}
					</div>
					<div className="column is-4 has-text-right">
						<div className="level">
							<a className="level-item" href={() => false} onClick={contact}>
								Nous contacter
							</a>
							<a className="level-item" href={() => false} onClick={about}>
								A propos
							</a>
						</div>
					</div>
				</div>
				<p className="subtitle has-text-centered has-text-white is-6">
					© 2021 RMDL. All right reserved.
				</p>
			</div>
		</footer>
	);
}
