import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import ErrorNotice from '../misc/ErrorNotice';
import { deletePost } from '../../api/Request';

export default function DeletePost({ match }) {
	const [error, setError] = useState();
	const { userData } = useContext(UserContext);
	const history = useHistory();
	const submit = async (e) => {
		e.preventDefault();
		try {
			await deletePost(window.location.pathname.split('/')[2])
			history.push('/posts');
		} catch (err) {
			console.log(err);
			err.response.data.msg && setError(err.response.data.msg);
		}
	};
	return (
		<div>
			{error && (
				<ErrorNotice message={error} clearError={() => setError(undefined)} />
			)}{' '}
			{userData.user ? (
				<button className="button is-darkred" onClick={submit}>
					Supprimer
				</button>
			) : (
				<></>
			)}
		</div>
	);
}
