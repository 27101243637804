import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';

import './main.css';

export default function App() {
	useEffect(() => {
		switch (window.location.pathname) {
			case '/':
				document.getElementById('backgroundSwitcher').className = 'landingpage';
				break;
			case '/login':
				document.getElementById('backgroundSwitcher').className =
					'blackBackground';
				break;
			case '/register':
				document.getElementById('backgroundSwitcher').className =
					'blackBackground';
				break;
			case '/newmusic':
				document.getElementById('backgroundSwitcher').className =
					'blackBackground';
				break;
			case '/apropos':
				document.getElementById('backgroundSwitcher').className =
					'blackBackground';
				break;
			case '/404':
				document.getElementById('backgroundSwitcher').className =
					'blackBackground';
				break;
			case '/calendar':
				document.getElementById('backgroundSwitcher').className =
					'blackBackground';
				break;
			case '/calendar/lists':
				document.getElementById('backgroundSwitcher').className =
					'blackBackground';
				break;

			default:
				document.getElementById('backgroundSwitcher').className =
					'whiteBackground';
				break;
		}
	}, []);

	return (
		<>
			<BrowserRouter forceRefresh={true}>
				<Router />
			</BrowserRouter>{' '}
		</>
	);
}
