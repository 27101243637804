import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';

export default function CreatePost() {
    const { userData } = useContext(UserContext);
    // const register = () => history.push('/register');
    // const login = () => history.push('/login');
    // const logout = () => {
    //     setUserData({
    //         token: undefined,
    //         user: undefined
    //     });
    //     localStorage.setItem('auth-token', '');
    // };
    const history = useHistory();

    const createPost = () => history.push('/newpost');
    return (
        <div>
            {userData.user ? (
                <button className="button is-darkred" onClick={createPost}>Nouvelle Annonce</button>
            ) : (
                    <>
                        
                    </>
                )}
        </div>

    )
}
