import React from 'react';
import { getConnectedUser } from '../../api/Functions';
import { getMusics } from '../../api/Request';
class Panel extends React.Component {
	state = {
		connectedUser: {},
		authRes: {},
		musics: {},
		musicsStatus: {},
	};
	componentDidMount() {
		this.getConnectedUser();
		this.renderAllMusics();
	}
	async getConnectedUser() {
		const user = await getConnectedUser();
			this.setState({ connectedUser: user.user });
			this.setState({ authRes: user.isStaff });
	}
	async renderAllMusics() {
		const res = await getMusics();
		let filterdStatus = res.data.filter((s) => {
			return s.status === 'En Attente';
		});
		this.setState({ musics: res.data });
		this.setState({ musicsStatus: filterdStatus });
	}
	goAdmin() {
		window.location.pathname = `/admin/`;
	}
	render() {
		return (
			<>
				{this.state.authRes ? (
					// <button className="button is-darkred" onClick={this.goAdmin}>
					// 	Panel Admin
					// </button>
					<button class="button is-darkred" onClick={this.goAdmin}>
						{this.state.musicsStatus.length !== 0 && (
							<span title="Badge top right" class="badge">{this.state.musicsStatus.length}</span>
						)}{' '}
					Panel Admin
					</button>
				) : (
					<></>
				)}
			</>
		);
	}
}
export default Panel;
