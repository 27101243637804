import React, { useState } from 'react';
import API from '../../api/Api';
import ErrorNotice from '../misc/ErrorNotice';

export default function RolesModifier(props) {
	const [_id, set_id] = useState();
	const [role, setRole] = useState();
	const [error, setError] = useState();

	const user = props.user;

	const submit = async (e) => {
		e.preventDefault();
		try {
			const updateRole = { _id, role };
			const updateUser = await API.post(
				`users/updaterole`,
				updateRole
			);
			window.location.pathname = `/admin/users/${props.user._id}`;
		} catch (err) {
			err.response.data.msg && setError(err.response.data.msg);
		}
	};
	return (
		<div className="modal is-active">
			<div className="modal-background"></div>
			<span className="modal-content">
				<div className="modal-card">
					<form onSubmit={submit}>
						<header className="modal-card-head">
							<p className="modal-card-title">
								Modification des rôles de l'utilisateurs
							</p>
							<button
								className="delete"
								aria-label="close"
								onClick={props.clearError}
							></button>
						</header>
						<section className="modal-card-body">
							{error && (
								<ErrorNotice
									message={error}
									clearError={() => setError(undefined)}
								/>
							)}
							<div className="field">
								<p className="title is-6">Roles</p>
								<div className="select is-fullwidth">
									<select onChange={(e) => setRole(e.target.value)}>
										<option selected>Rôle</option>
										<option>Utilisateur</option>
										<option>Animateur</option>
										<option>Admin</option>
									</select>
								</div>
								<p className="help is-danger">* Renseignement Obligatoire</p>
								<p className="help is-info">
									Utilisateur: il aura les permissions d'un simple utilisateur
								</p>
								<p className="help is-info">
									Animateur: il aura des permissions de gérer les musiques et
									les annonces
								</p>
								<p className="help is-info">
									Admin: il aura toutes les permissions
								</p>
							</div>
						</section>
						<footer className="modal-card-foot">
							<input
								className="button is-success"
								type="submit"
								value="Changer"
								onClick={() => {
									set_id(user._id);
								}}
							/>
							<button className="button" onClick={props.clearError}>
								Cancel
							</button>
						</footer>
					</form>
				</div>
			</span>
		</div>
	);
}
