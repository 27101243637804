import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';

export default function AuthOptions() {
	const { userData, setUserData } = useContext(UserContext);

	const history = useHistory();

	const register = () => history.push('/register');
	const login = () => history.push('/login');
	const logout = () => {
		setUserData({
			token: undefined,
			user: undefined
		});
		localStorage.setItem('auth-token', '');
	};

	return (
    <>
			{userData.user ? (
				<button className="button is-darkred" onClick={logout}>
					Deconnexion
				</button>
			) : (
				<>
					<div className="buttons">
						<button className="button is-darkred" onClick={register}>
							S'inscrire
						</button>
              			<button className="button is-darkred" onClick={login}>
                			Connexion
              			</button>
					</div>
				</>
        )}
      </>
	);
}
