import React from 'react';
import API from '../../../api/Api';
import PasswordModifier from '../../auth/PasswordModifier';
import RolesModifier from '../../auth/RolesModifier';
import NameModifier from '../../auth/nameModifier';
import UserDeleter from '../../auth/UserDeleter';
class User extends React.Component {
	state = {
		user: {},
		connectedUser: {},
		authRes: {},
		showPasswordModifier: false,
		showRolesModifier: false,
		showNameModifier: false,
		showUserDeleter: false
	};
	componentDidMount() {
		this.getUser();
		this.getConnectedUser();
	}
	showPasswordModifier(type) {
		this.setState({ showPasswordModifier: type });
	}
	showRolesModifier(type) {
		this.setState({ showRolesModifier: type });
	}
	showNameModifier(type) {
		this.setState({ showNameModifier: type });
	}
	showUserDeleter(type) {
		this.setState({ showUserDeleter: type });
	}
	async getConnectedUser() {
		let token = localStorage.getItem('auth-token');
		if (token === null) {
			return;
		}
		const tokenRes = await API.post(`users/tokenIsValid`, null, {
			headers: { 'auth-token': token }
		});

		if (tokenRes.data.status) {
			const userRes = await API.get(`users/`, {
				headers: { 'auth-token': token }
			});

			this.setState({ connectedUser: userRes.data });

			try {
				const resIsAdmin = await API.post(
					`users/isAdmin`,
					tokenRes.data.user
				);
				console.log(resIsAdmin.data);
				this.setState({ authRes: resIsAdmin.data });
			} catch (error) {
				console.log(error);
			}
		}
	}
	async getUser() {
		const res = await API.get(
			`users/${this.props.match.params.id}`
		);
		this.setState({ user: res.data });
	}
	goHome() {
		window.location.pathname = `/`;
	}

	render() {
		return (
			<>
				{this.state.authRes.status === '200' ? (
					<section className="section allsection">
						<div className="container py-4">
							{this.state.showRolesModifier && (
								<RolesModifier
									user={this.state.user}
									clearError={() => this.showRolesModifier(false)}
								/>
							)}{' '}
							{this.state.showPasswordModifier && (
								<PasswordModifier
									user={this.state.user}
									clearError={() => this.showPasswordModifier(false)}
								/>
							)}{' '}
							{this.state.showNameModifier && (
								<NameModifier
									user={this.state.user}
									clearError={() => this.showNameModifier(false)}
								/>
							)}{' '}
							{this.state.showUserDeleter && (
								<UserDeleter
									user={this.state.user}
									clearError={() => this.showUserDeleter(false)}
								/>
							)}{' '}
							<h2 className="title has-text-centered mb-6">
								Paramètres de {this.state.user.displayName}{' '}
							</h2>
							<div className="container has-text-centered py-4">
								<h2 className="title mb-6">Informations</h2>
								<div className="section allsection">
									<div className="columns">
										<div className="column is-3">
											<h4 className="title is-spaced has-text-centered is-4">
												Identifiant
											</h4>
											<p className="subtitle is-6">{this.state.user._id}</p>
										</div>
										<div className="column is-3">
											<h4 className="title is-spaced has-text-centered is-4">
												Email
											</h4>
											<p className="subtitle is-6">{this.state.user.email}</p>
										</div>
										<div className="column is-3">
											<h4 className="title is-spaced has-text-centered is-4">
												Token
											</h4>
											<p className="subtitle is-6">
												{this.state.user.password}
											</p>
										</div>
										<div className="column is-3">
											<h4 className="title is-spaced has-text-centered is-4">
												Role
											</h4>
											<p className="subtitle is-6">{this.state.user.roles}</p>
										</div>
									</div>
								</div>
								<div className="section">
									<div className="columns">
										<div className="column is-3">
											<h4 className="title is-spaced is-4">
												Compte Utilisateur
											</h4>
											<p className="subtitle is-6">
												Modifier le mot de passe de l'utilisateur.
											</p>
											<button
												className="button is-darkred"
												onClick={() => this.showPasswordModifier(true)}
											>
												Modifier
											</button>
										</div>
										<div className="column is-3">
											<h4 className="title is-spaced is-4">
												Roles Utilisateur
											</h4>
											<p className="subtitle is-6">
												Modifier les rôles de l'utilisateur. Permet d'acceder au
												panel admin.
											</p>
											<button
												className="button is-darkred"
												onClick={() => this.showRolesModifier(true)}
											>
												Modifier
											</button>
										</div>
										<div className="column is-3">
											<h4 className="title is-spaced is-4">
												Pseudo Utilisateur
											</h4>
											<p className="subtitle is-6">
												Changer le nom d'afichage de l'utilisateur.
											</p>
											<button
												className="button is-darkred"
												onClick={() => this.showNameModifier(true)}
											>
												Modifier
											</button>
										</div>
										<div className="column is-3">
											<h4 className="title is-spaced is-4">
												Supprimer le compte
											</h4>
											<p className="subtitle is-6">
												Supprimer le compte de l'utilisateur à jamais.
											</p>
											<button
												className="button is-darkred"
												onClick={() => this.showUserDeleter(true)}
											>
												Supprimer
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				) : (
					<section className="section allsection">
						<div className="container has-text-centered">
							<div className="columns is-centered">
								<div className="column is-7">
									<h1 className="title is-1">403</h1>
									<p className="subtitle is-3">Accès interdit</p>
									<p>
										Woops! On dirait que vous cherchez quelque chose
										d'interdit...{' '}
									</p>
								</div>
							</div>
							<button
								className="button is-primary"
								onClick={this.goHome.bind()}
							>
								Retour à la maison
							</button>
						</div>
					</section>
				)}
			</>
		);
		//}
		// } else {
		// 	return (
		// 	)
		// }
	}
}

export default User;
