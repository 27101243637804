import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(<App />, document.querySelector("#root"));
document.addEventListener('DOMContentLoaded', () => {

// Get all "navbar-burger" elements
const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

// Check if there are any navbar burgers
if ($navbarBurgers.length > 0) {
  
  // Add a click event on each of them
  $navbarBurgers.forEach( el => {
    el.addEventListener('click', () => {
      
      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);
      
      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');
      
    });
  });
}

// Get all "navbar-burger" elements
const $dropdown = Array.prototype.slice.call(document.querySelectorAll('.dropdown'), 0);

// Check if there are any navbar burgers
if ($dropdown.length > 0) {
  
  // Add a click event on each of them
  $dropdown.forEach( el => {
    el.addEventListener('click', () => {
      
      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);
      
      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');
      
    });
  });


}

// // Dropdowns

// var $dropdowns = getAll('.dropdown');
  
  // function getAll(selector) {
  //   return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
  // }
  // console.log($dropdowns)
  //   $dropdowns.forEach( el => {
  //     el.addEventListener('click', function (event) {
  //       const target = el.dataset.target;
  //         const $target = document.getElementById(target);
  //       //event.stopPropagation();
  //       el.classList.toggle('is-active');
  //       $target.classList.toggle('is-active');
  //     });
  //   });

  //   document.addEventListener('click', function (event) {
  //     closeDropdowns();
  //   });


  // function closeDropdowns() {
  //   $dropdowns.forEach(el => {
  //     el.classList.remove('is-active');
  //   });
  // }

  // // Close dropdowns if ESC pressed
  // document.addEventListener('keydown', function (event) {
  //   var e = event || window.event;
  //   if (e.keyCode === 27) {
  //     closeDropdowns();
  //   }
  // });

  // Functions

});
// window.onload = () => {

// }