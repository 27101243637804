import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import API from '../../api/Api';
import ErrorNotice from '../misc/ErrorNotice';

export default function Register() {
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [passwordCheck, setPasswordCheck] = useState();
	const [displayName, setDisplayName] = useState();
	const [error, setError] = useState();

	const { setUserData } = useContext(UserContext);
	const history = useHistory();

	const submit = async (e) => {
		e.preventDefault();

		try {
			const newUser = { email, password, passwordCheck, displayName };
			await API.post(`users/register`, newUser);
			const loginRes = await API.post(`users/login`, {
				email,
				password
			});
			setUserData({
				token: loginRes.data.token,
				user: loginRes.data.user
			});
			localStorage.setItem('auth-token', loginRes.data.token);
			history.push('/');
		} catch (err) {
			err.response.data.msg && setError(err.response.data.msg);
		}
	};

	return (
		// <div className="page allsection">
		// 	<h2> Register </h2>{' '}
		// 	{error && (
		// 		<ErrorNotice message={error} clearError={() => setError(undefined)} />
		// 	)}{' '}
		// 	<form className="form" onSubmit={submit}>
		// 		<label htmlFor="register-email"> Email </label>{' '}
		// 		<input
		// 			id="register-email"
		// 			type="email"
		// 			onChange={(e) => setEmail(e.target.value)}
		// 		/>{' '}
		// 		<label htmlFor="register-password"> Password </label>{' '}
		// 		<input
		// 			id="register-password"
		// 			type="password"
		// 			onChange={(e) => setPassword(e.target.value)}
		// 		/>{' '}
		// 		<input
		// 			type="password"
		// 			placeholder="Verify password"
		// 			onChange={(e) => setPasswordCheck(e.target.value)}
		// 		/>{' '}
		// 		<label htmlFor="register-display-name"> Display name </label>{' '}
		// 		<input
		// 			id="register-display-name"
		// 			type="text"
		// 			onChange={(e) => setDisplayName(e.target.value)}
		// 		/>{' '}
		// 		<input type="submit" value="Register" />
		// 	</form>{' '}
		// </div>
		<section className="section is-large">
			<div className="container has-text-centered">
				<div className="columns is-centered">
					<div className="column is-5 is-4-desktop">
						<form onSubmit={submit}>
							<p className="title has-text-white"> Inscription RMDL </p>{' '}
							{error && (
								<ErrorNotice
									message={error}
									clearError={() => setError(undefined)}
								/>
							)}{' '}
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="text"
										placeholder="Prénom Nom"
										onChange={(e) => setDisplayName(e.target.value)}
									/>{' '}
								</div>{' '}
							</div>{' '}
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="email"
										placeholder="Email"
										onChange={(e) => setEmail(e.target.value)}
									/>{' '}
								</div>{' '}
							</div>{' '}
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="password"
										placeholder="Mot de Passe"
										onChange={(e) => setPassword(e.target.value)}
									/>{' '}
								</div>{' '}
							</div>{' '}
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="password"
										placeholder="Confirmer le mot de passe"
										onChange={(e) => setPasswordCheck(e.target.value)}
									/>{' '}
								</div>{' '}
							</div>{' '}
							<div className="field">
								<input
									className="button is-darkred is-fullwidth"
									type="submit"
									value="S'inscrire !"
								/>
							</div>{' '}
						</form>{' '}
					</div>{' '}
				</div>{' '}
			</div>{' '}
		</section>
	);
}
