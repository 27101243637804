/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
import React from 'react';
// import Calendar from '@ericz1803/react-google-calendar'
import { css } from '@emotion/react';

const API_KEY = 'AIzaSyBOSHHa-Nv3uWat1fo0Gjtapq2SxokLsmY';
let calendars = [
	{ calendarId: 'radiomdl.fv@gmail.com' },
	{
		calendarId: 'YOUR_CALENDAR_ID_2',
		color: '#B241D1' //optional, specify color of calendar 2 events
	}
];

let styles = {
	//you can use object styles (no import required)
	calendar: {
		borderWidth: '3px', //make outer edge of calendar thicker
		backgroundColor: 'white'
	},

	//you can also use emotion's string styles
	today: css`
		/* highlight today by making the text red and giving it a red border */
		color: red;
		border: 1px solid red;
	`
};

class calendar extends React.Component {
	render() {
		return (
			<section className="section is-medium allsection">
				<div class="hero">
					<div class="hero-body has-text-centered">
						<div class="container has-text-centered">
							<h2 class="title is-spaced  has-text-white">
								Programme de la semaine
							</h2>
							<h1 class="subtitle  has-text-white">
								Pour avoir plus d'informations concernant le programme de la
								prochaine émission:
							</h1>
							<a className="control button is-darkred" href="/calendar/lists">
								Listes des programmes
							</a>
						</div>
					</div>
				</div>
				<div className="has-text-centered">
					<iframe
						src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FParis&amp;src=cmFkaW9tZGwuZnZAZ21haWwuY29t&amp;src=ZnIuZnJlbmNoI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%b2000b&amp;color=%b2000b&amp;showTz=0&amp;showCalendars=0&amp;showTabs=1&amp;showPrint=0&amp;showTitle=0&amp;mode=AGENDA"
						width="500"
						height="600"
					></iframe>
				</div>
				{/* <Calendar apiKey={API_KEY} calendars={calendars} styles={styles} /> */}
			</section>
			//             <section className="section allsection">
			//                 <div class="has-text-centered">
			//   <div class="columns">
			//     <div class="column">
			//       <nav class="level">
			//         <div class="level-item">
			//           <button class="button is-success">
			//             Précédent
			//           </button>
			//         </div>
			//         <div class="level-item has-text-centered">
			//           <div>
			//             <p class="heading">Week 1</p>
			//             <p class="subtitle has-text-white">01/01/2020 - 05/01/202</p>
			//           </div>
			//         </div>
			//         <div class="level-item">
			//           <button class="button is-success">
			//             Suivant
			//           </button>
			//         </div>
			//       </nav>
			//     </div>
			//   </div>
			//   <div class="columns">
			//     <div class="column">
			//       First column
			//     </div>
			//     <div class="column">
			//       Second column
			//     </div>
			//     <div class="column">
			//       Third column
			//     </div>
			//     <div class="column">
			//       Fourth column
			//     </div>
			//     <div class="column">
			//       Fifth column
			//     </div>
			//   </div>
			// </div>
			// 			</section>
		);
	}
}

export default calendar;
