import React, { useEffect} from "react";
import Logo from '../../assets/rmdlogo.png'
// eslint-disable-next-line no-unused-vars
import Arrow from '../../assets/down-arrow.svg'
import { getConnectedUser } from "../../api/Functions";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'


export default function Home() {
  useEffect(() => {
    getConnectedUser().then((res) => {
      console.log(res.user)
      console.log(res.isStaff)
    })
	}, []);

  return (
    <>
    <section className="hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container">
          <p className="title has-text-white ">
            Radio Maison Des lycéens
          </p>
        </div>
      </div>
      
      {/* <img src={Arrow} className="arrow" alt="arrow"/> */}
    </section>
    <section className="landingpagePart2">
    <section className="container is-fullheight-with-navbar">
        <section className="hero">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-spaced has-text-white">La RMDL c'est quoi ?</h1>
              <h2 className="subtitle has-text-white">
                La Radio Maison Des Lycéens est un club qui propose des rendez-vous
                musicaux et de sujets d'actualités dans l'Agora
              </h2>
              <div className="buttons is-centered">
                <a className="control button is-darkred" href="/register">
                  S'inscrire
                </a>
                <a className="control button is-light" href="/apropos">
                  En savoir plus
                </a>
              </div>
            </div>
          </div>
        </section>
    </section>
    <section className="container is-fullheight-with-navbar">
          <hr className="my-6" />
          <div className="container py-4">
            <h2 className="title has-text-centered has-text-white mb-6">
              Nos Musiques
            </h2>
            <div className="columns is-vcentered">
              <div className="column">
                <h2 className="title has-text-white is-spaced">
                  Choisir ses musiques
                </h2>
                <p className="subtitle has-text-white">
                  Nous faisons passer nos musiques mais maintenant vous pouvez
                  aussi proposer les vôtres
                </p>
                <div className="buttons">
                <a className="button is-darkred" href="/newmusic">
                  C'est partit!
                </a>
                <a className="button is-darkred" href="/music">
                  Voir Liste
                </a>
                </div>
              </div>
              <div className="column">
                <img src={Logo} alt="" />
              </div>
            </div>
            <hr className="my-6" />
          </div>
    </section>
    </section>
    </>

  );
}
