/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import AnnounceIcon from '../../assets/bullhorn-solid.svg';
import UserIcon from '../../assets/users-solid.svg';
import MusicIcon from '../../assets/music-solid.svg';
import WaitIcon from '../../assets/edit-solid.svg';
import LoadingIcon from '../../assets/icons8-cd.gif';
import { notify } from '../misc/Notifier';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getConnectedUser } from '../../api/Functions';
import { getMusics, getPosts, getUsers } from '../../api/Request';
class Manager extends React.Component {
	state = {
		user: {},
		users: {},
		musics: {},
		musicsStatus: {},
		connectedUser: {},
		authRes: {},
		posts: {},
		loading: true
	};
	componentDidMount() {
		this.getConnectedUser()
			.then(() => {
				setTimeout(() => {
					this.renderAllUser().then(() => {
						this.renderAllMusics().then(() => {
							this.getPosts().then(() => {
								this.setState({ loading: false });
							});
						});
					});
				}, 5000);
			})
			.catch((error) => {
				console.log(error);
				notify(toast, 'error', error.message);
			});
		// this.renderAllMusicsStatus();
	}
	async renderAllUser() {
		const res = await getUsers();
		this.setState({ users: res.data });
	}
	async renderAllMusics() {
		const res = await getMusics();
		let filterdStatus = res.data.filter((s) => {
			return s.status === 'En Attente';
		});
		this.setState({ musics: res.data });
		this.setState({ musicsStatus: filterdStatus });
	}
	// async renderAllMusicsStatus() {
	// 	const res = await Axios.get('${API_DOMAIN}music/');
	// 	this.setState({ musics: res.data  });
	// }
	async getConnectedUser() {
		const user = await getConnectedUser();
		this.setState({ connectedUser: user.user });
		this.setState({ authRes: user.isStaff });
	}
	async getPosts() {
		const res = await getPosts();
		this.setState({ posts: res.data });
	}
	goMusicManager() {
		window.location.pathname = `/admin/music/`;
	}
	goMusic() {
		window.location.pathname = `/music/`;
	}
	goPosts() {
		window.location.pathname = `/posts/`;
	}
	goCalendar() {
		window.location.pathname = `/calendar/`;
	}
	goMail() {
		window.location.href = 'https://mail.radio-fv.ml';
	}
	goUsers() {
		window.location.pathname = `/admin/users/`;
	}
	goHome() {
		window.location.pathname = `/`;
	}
	// 	async getAdmin() {
	// 		let data = this.state.connectedUser.id;
	// 		// let id = { id: "5fc2ffc79722c5607f0db5c5"}
	// 		console.log(data)
	// 		const resIsAdmin = await Axios.get(`${API_DOMAIN}users/isAdmin`, data);
	// 		console.log(resIsAdmin)
	// 	this.setState({ authRes: resIsAdmin.data });
	// }
	// renderName() {
	// 	return { displayName: this.state.user.displayName };
	// }
	render() {
		return this.state.loading ? (
			<>
				{this.state.authRes ? (
					<>
						<section className="section allsection">
							<div className="container has-text-centered">
								<h2 className="title">RMDL Admin</h2>
							</div>
						</section>
						<section className="section">
							<div className="container">
								<div className="columns is-multiline">
									<div className="column is-6-tablet is-3-desktop">
										<div className="box" onClick={this.goPosts}>
											<div className="media">
												<div className="media-left">
													<figure className="image is-48x48">
														<img src={AnnounceIcon} alt="" />
													</figure>
												</div>
												<div className="media-content has-text-right">
													<h3 className="title is-1 has-text-info">
														{this.state.posts.length}
													</h3>
												</div>
											</div>
											<h4 className="title">Annonces</h4>
											<progress
												className="progress is-info"
												role="progressbar"
												value={this.state.posts.length}
												max="100"
											></progress>
										</div>
									</div>
									<div className="column is-6-tablet is-3-desktop">
										<div className="box" onClick={this.goUsers}>
											<div className="media">
												<div className="media-left">
													<figure className="image is-48x48">
														<img src={UserIcon} alt="" />
													</figure>
												</div>
												<div className="media-content has-text-right">
													<h3 className="title is-1 has-text-danger">
														{this.state.users.length}
													</h3>
												</div>
											</div>
											<h4 className="title">Utilisateurs</h4>
											<progress
												className="progress is-danger"
												role="progressbar"
												value={this.state.users.length}
												max="100"
											></progress>
										</div>
									</div>
									<div className="column is-6-tablet is-3-desktop">
										<div className="box" onClick={this.goMusic}>
											<div className="media">
												<div className="media-left">
													<figure className="image is-48x48">
														<img src={MusicIcon} alt="" />
													</figure>
												</div>
												<div className="media-content has-text-right">
													<h3 className="title is-1 has-text-success">
														{this.state.musics.length}
													</h3>
												</div>
											</div>
											<h4 className="title">Musiques</h4>
											<progress
												className="progress is-success"
												role="progressbar"
												value={this.state.musics.length}
												max="100"
											></progress>
										</div>
									</div>
									<div className="column is-6-tablet is-3-desktop">
										<div className="box" onClick={this.goMusicManager}>
											<div className="media">
												<div className="media-left">
													<figure className="image is-48x48">
														<img src={WaitIcon} alt="" />
													</figure>
												</div>
												<div className="media-content has-text-right">
													<h3 className="title is-1 has-text-info">
														{this.state.musicsStatus.length}
													</h3>
												</div>
											</div>
											<h4 className="title">En attente</h4>
											<progress
												className="progress is-info"
												role="progressbar"
												value={this.state.musicsStatus.length}
												max="100"
											></progress>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="section allsection">
							<div className="container py-4">
								<h2 className="title has-text-centered mb-6">Panel's</h2>
								<div className="columns">
									<div className="column is-3">
										<h4 className="title is-spaced is-4">Mail</h4>
										<p className="subtitle is-6">Acceder au mails de la RMDL</p>
										<a
											href={() => false}
											className="button is-darkred"
											onClick={this.goMail}
										>
											Suivant
										</a>
									</div>
									<div className="column is-3">
										<h4 className="title is-spaced is-4">Utilisateurs</h4>
										<p className="subtitle is-6">
											Acceder au panel des utilisateurs
										</p>
										<a
											href={() => false}
											className="button is-darkred"
											onClick={this.goUsers}
										>
											Suivant
										</a>
									</div>
									<div className="column is-3">
										<h4 className="title is-spaced is-4">Musiques</h4>
										<p className="subtitle is-6">
											Acceder au panel des musiques
										</p>
										<a
											href={() => false}
											className="button is-darkred"
											onClick={this.goMusicManager}
										>
											Suivant
										</a>
									</div>
									<div className="column is-3">
										<h4 className="title is-spaced is-4">Programme</h4>
										<p className="subtitle is-6">
											Acceder au panel du programme
										</p>
										<a
											href={() => false}
											onClick={this.goCalendar}
											className="button is-darkred"
										>
											Suivant
										</a>
									</div>
								</div>
							</div>
						</section>
					</>
				) : (
					<section className="section allsection">
						<div className="container has-text-centered">
							<div className="columns is-centered">
								<div className="column is-7">
									<h1 className="title is-1">403</h1>
									<p className="subtitle is-3">Accès interdit</p>
									<p>
										Woops! On dirait que vous cherchez quelque chose
										d'interdit...{' '}
									</p>
								</div>
							</div>
							<button
								className="button is-primary"
								onClick={this.goHome.bind()}
							>
								Retour à la maison
							</button>
						</div>
					</section>
				)}
			</>
		) : (
			<>
				<section className="loadingPage is-fullheight-with-navbar">
					<div className="container has-text-centered">
						<img src={LoadingIcon} width="100" height="100" alt="Loading" />
					</div>
				</section>
			</>
		);
	}
}
export default Manager;
