import React from 'react';
import MusicListItem from './MusicListItem';
import { getMusics } from '../../api/Request';

class PostList extends React.Component {
	state = {
		musics: []
	};

	renderList() {
		this.state.musics.sort(function(a,b){
			// Turn your strings into dates, and then subtract them
			// to get a value that is either negative, positive, or zero.
			return new Date(b.createdAt) - new Date(a.createdAt);
		  });

		return this.state.musics.map((music, i) => {
			return <MusicListItem music={music} key={music._id} />;
		});
	}
	componentDidMount() {
		this.getMusics();
	}

	async getMusics() {
		const res = await getMusics();
		this.setState({ musics: res.data });
	}

	render() {
		return (
			<>
				<section className="section allsection">
					<div className="container has-text-centered py-4">
						<h2 className="title has-text-centered mb-6">Musiques</h2>
						<div class="table-container">
						<table className="table is-fullwidth">
							<thead>
								<tr>
									<th>Nom</th>
									<th>Utilisateurs</th>
									<th>Style</th>
									<th>Like</th>
									<th>UnLike</th>
									<th>Votes</th>
									<th>Statut</th>
									<th>
										<abbr title="Voir Plus">Info</abbr>
									</th>
								</tr>
							</thead>
							<tfoot>
								<tr>
									<th>Nom</th>
									<th>Utilisateurs</th>
									<th>Style</th>
									<th>Like</th>
									<th>UnLike</th>
									<th>Votes</th>
									<th>Statut</th>
									<th>
										<abbr title="Voir Plus">Info</abbr>
									</th>
								</tr>
							</tfoot>
							<tbody>{this.renderList()}</tbody>
						</table>
						</div>
					</div>
					{/* <section className="section allsection">
          <section className="section">
            <div className="container py-4">
              <h2 className="title has-text-centered mb-6">Musiques</h2>
              <div className="panel">
                <nav className="level">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="title">Nom de la musique</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="title">Utilisateur</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="title">Type</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="title">Statut</p>
                    </div>
                  </div>
                </nav>
                <div className="panel-block is-block py-3 px-4">
                  <a className="media" href={() => false}>
                    <div className="media-content">{this.renderList()}</div>
                    <div className="media-right">
                      <img
                        src="placeholder/icons/chevron-circle-down.svg"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section> */}
					<nav>
						<div
							className="pagination is-centered"
							role="navigation"
							aria-label="pagination"
						>
							<a className="pagination-previous" href={() => false} disabled>
								Precedent
							</a>
							<a className="pagination-next" href={() => false} disabled>
								Suivant
							</a>
							<ul className="pagination-list">
								<li>
									<a
										className="pagination-link is-current"
										aria-label="Page 1"
										aria-current="page"
										href={() => false}
									>
										1
									</a>
								</li>
								{/* <li><a className="pagination-link" aria-label="Goto page 2">2</a></li>
        <li><a className="pagination-link" aria-label="Goto page 3">3</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 47">5</a></li>
        <li><span className="pagination-ellipsis">…</span></li>
        <li><a className="pagination-link" aria-label="Goto page 86">10</a></li> */}
							</ul>
						</div>
					</nav>
					{/* </div> */}
				</section>
			</>
		);
	}
}

export default PostList;
